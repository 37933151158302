@import url('https://fonts.googleapis.com/css?family=Lato');

.navbar-links {
  display: flex;
  z-index: 3;
  position: relative;
  justify-content: space-between;
  padding: 20px;
  align-items: center;

}

.navbar-links ul {
  display: flex;
  list-style: none;
  width: 95%;
  justify-content: flex-end;
  padding: 0;
  flex-direction: row;
}

.navbar-links ul li {
  margin: 0 1px;
  padding: 10px;
}


.link {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    font-family: lato, sans-serif;
    letter-spacing: 3px;
    font-size: .75em;
}

.link-color {
  color: black;
}

.active{
    text-decoration: underline;
    text-transform: uppercase;
    color: black;
    font-family: lato, sans-serif;
    letter-spacing: 3px;
    font-size: .75em;
    cursor: default;
}

.link:hover{
    color: rgb(143, 143, 143);
  }

.icon:hover{
  color: rgb(143, 143, 143);
}

.fab{
    color:black;
    font-family: "Font Awesome 5 Brands";
}

.toggle-button{
  position: absolute;
  top: .75rem;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 21px;
  border-style: none;
  background: transparent;
  transition: .5 ease-in-out;
}

.logo{
  height: 70px;
}


@media (max-width: 430px){

  .logo{
    height: 51px;
  }
  .toggle-button{
    display: flex;
    z-index: 100000;
    right: 12px;
  }

  .bar{
    height: 2px;
    width: 37px;
    background-color: #383838;
    border-radius: 0;
    transition: all .5s ease-in-out;
  }
  
  .bar::before,.bar::after{
    content:'';
    position: absolute;
    width: 37px;
    height: 2px;
    background: #383838;
    transition: all .5s ease-in-out;
  }
  

  .bar::before{
    transform: translate(-19px,-12px);
  }

  .bar::after{
    transform: translate(-19px,12px);
  }

  /*animation*/
  .toggle-button.open .bar{
    transform: translateX(-50px);
    background: transparent;
  }

  .toggle-button.open .bar::before{
    transform: rotate(45deg) translate(30px,-22px);
  }

  .toggle-button.open .bar::after{
    transform: rotate(-45deg) translate(22px, 30px);
  }

  .navbar-links{
    display: none;
  }

  .navbar{
    top: 104%;
    /* position: absolute;   */
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    }

    .navbar-links.active{
      display: flex;
      flex-direction: column;
      min-height: 100%;
      text-decoration: none;
      background-color: white;
      justify-content: space-around;
      padding: 0;
      height: 100vh;
      z-index: 1000;
      width: 100%;
      position: absolute;
    }
 


}