@import url('https://fonts.googleapis.com/css?family=Lato');

body{
  width: 100%;
}
.container {
    cursor: pointer;
    background-color: transparent;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 1.5rem;
    
  }

  .title{
    position: absolute;
    z-index: 1;
    transform: translate3d(-50%, -50%,0px);
    opacity: 1;
    top: 10%;
    left: 30%;
  }

  h2{
    font-family: Lato, sans-serif;
    color: black;
    letter-spacing: 5.3px;
    font-weight: unset;
  }

  h1 {
    font-family: Lato, sans-serif;
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1.2px white;
    font-size: 50px;
    font-weight: unset;
    letter-spacing: 5.3px;
    margin-bottom: 20px;
  }

  h1:hover {
    color: white;
    /* cursor: pointer; */
    opacity: 1;
  }

  .subtitle{
    color: white;
    font-family: lato, sans-serif;
    font-size: 25px;
    font-weight: unset;
    letter-spacing: 7.3px;
  }

  /* a{
    position: relative;
  } */
  
  p{
    color: white;
    font-family: arial;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  

  .downArrow{
    z-index: 2;
    transform: translate3d(-50%, -40%, 0px);
    opacity: 1;
    position: absolute;
    top:80%;
    left: 50%;
    cursor: pointer;
  }

  a:hover .hideOnHover{
    display: none;
    visibility: hidden;
    overflow: hidden;
  }

  .showOnHover{
    display:none;
    visibility: hidden;
    overflow: hidden;
    
  }

  a:hover .showOnHover{
    display: inline-block;
    overflow: auto;
    visibility: visible;
  }

  .backVid{
    /* height: 100%;
    width: 100%; */
    /* height: 100%; */
    /* width needs to be over 100% to cover whole screen , might need to adjust according to device sizes */
    width: 114%; 
  }

  .lucy-title{
    font: 900 56px Arial;
    -webkit-text-fill-color: trasparent;
  }

 /*mobile screen */

  @media (max-width: 430px){

    .downArrow{
      z-index: 2;
      transform: translate3d(-50%, -40%, 0px);
      opacity: 1;
      position: absolute;
      bottom:20%;
      left: 40%;
      cursor: pointer;
    }
  
    .title{
      left: 47%;
      top: 10%;
    }

    .backVid{
          /* Make video to at least 100% wide and tall */
      min-width: 100%; 
      min-height: 100%; 

      /* Setting width & height to auto prevents the browser from stretching or squishing the video */
      width: auto;
      height: auto;

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .container{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 110%;
      height: 100%; 
      overflow: hidden;
    }

    .lucy-title{
      font: 900 26px Arial;
    }

    .subtitle{
      letter-spacing: 7.3px;
      font-size: 15px;
    }



    
  }
  

 