.stills{
    float: left;
  width:  400px;
  height: 200px;
  object-fit: cover;
}

.stillContainer{
    display: flex;
    justify-content: center;
}

.video-wrapper{
  position: relative;
  padding-bottom: 40.25%;
}

.video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.video-credits{
display: flex;
flex-direction: column;
margin-top: 14px;
margin-bottom: 14px;
}

.video-credits strong {
  text-transform: uppercase;
  font-weight: bold;
}

p{
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.04em;
  text-transform: lowercase;
  color: black;
  font-family:"Lato";
  letter-spacing: 2.3px;

}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */


  
}