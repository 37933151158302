@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap");

.wrapper{
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    top: 15%;
    position: relative;
    justify-content: space-around;
    width: 70%;
    padding: 10%;
    font-family: "Lato";
    text-transform: uppercase;
    padding-bottom: 2.3 em;
}

.textWrapper{
    font-family: "Lato";
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 400;
}

.detailWrapper{

    font-family: "Lato";
  text-transform: uppercase;
  font-weight: 100;

}

@media(max-width: 430px){
    .wrapper{
        flex-direction: column;
        height: 100vh;
        margin: 50px;
        justify-content: unset;
        padding: 0;
    }

    .textWrapper{
        display: none;
    }

    .menuVideoImg{
        width: 100vw;
    }


}
