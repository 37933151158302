.bio{
    font-family: "Lato";
    letter-spacing: .03em;
}

.bio-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
}

.profile-wrap{
    max-width: 800px;
    padding-left: 30px;
    padding-right: 60px;
}

.new-line{
    white-space: pre-line;
}

@media (max-width: 430px) {
    .bio-wrap{
        flex-direction: column;
    }

    .bio{
        max-width: 300px;
    }

    .profile-wrap{
        margin-top: 30px;
        padding-left: 0;
    }
}