@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap");

#root{
  overflow: hidden;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gridContainer{
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0px;
  grid-column-gap: 4px;
  margin: 0;
  z-index: 3;
  position: relative;
}

.menuVideoImg {
  float: left;
  width:  400px;
  height: 200px;
  object-fit: cover;
}

.projectGrid{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 0px 30px;
  justify-content: center;
  background: rgba(255,255,255,0.5);
  opacity: 50%;

}

.projectGrid:hover {
  opacity: 100%;
  transition: 350ms ease-in-out;
}
/* 
.outerwrap:hover + .overlay{
  display: block;
} */

.text--hidden {
  display: block;
}

.projectGrid:hover + .linkTextContainer {
  display: none;
}

.linkTextContainer {
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-family: Lato;
  cursor: pointer;
  align-items: center;
  top: 0%;
  text-decoration: none;
  color: black;
  letter-spacing: 3px;
}

.videoGenre-text{
  font-size: 14px;
}

.slug-link{
  text-decoration: none;
  font-family: 'Lato';
}

.outerwrap{
  position: relative;
  display: flex;
  justify-content: center;
}

/* .overlay:hover {
  opacity: 50%;
  transition: 350ms ease-in-out;
} */

@media only screen and (max-width: 1200px) {
 .projectGrid{
  flex: 0 0 50%;
 }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
 
  #mainBody{
    position: absolute;
    top: 113%;
  }

  .mainBodyCategory{
    margin-top: 40px;
  }

  .projectGrid{
    justify-content: center;
  }


}